<script setup>
import VueTypes from "vue-types";
import { useMoment } from "@/plugins/moment";
import { ref } from "vue";

const moment = useMoment();

const props = defineProps({
  isAdminMessage: VueTypes.bool.required,
  avatar: VueTypes.string.required,
  date: VueTypes.string.required,
  message: VueTypes.string.required,
  isSystem: VueTypes.bool.required,
  isDeleted: VueTypes.bool.required,
  avatarVisible: VueTypes.bool.required,
  useHead: VueTypes.bool.required,
});

const systemMessageTitle = ref(props.isAdminMessage ? "Yönetici Mesajı" : "Sistem Mesajı");

const emit = defineEmits(["is:open-detail"]);
</script>

<template>
  <div class="chat-message">
    <div class="chat-message-side" v-if="!isSystem">
      <a-avatar
        v-if="avatarVisible"
        :src="avatar"
        :size="{ xs: 40, sm: 40, md: 40, lg: 40, xl: 40, xxl: 40 }"
      ></a-avatar>
    </div>
    <div class="chat-message-content">
      <div class="chat-message-content-head" v-if="useHead">
        <span class="chat-datetime is-description">{{
          moment(Date.parse(date)).format("HH:mm")
        }}</span>
      </div>
      <div class="chat-message-content-body">
        <template v-if="isSystem">
          <p class="chat-system-label no-margin text-center" v-if="isSystem">
            <span class="text-primary">
              &#183; {{ systemMessageTitle }} &#183;
            </span>
          </p>
          <div
            v-if="isSystem"
            class="chat-message-text system-message-text"
            v-html="message"
          ></div>
        </template>
        <div
          v-else
          class="chat-message-text"
          :class="{ 'text-italic': isDeleted }"
          v-html="
            isDeleted ? 'Bu mesaj silindi' : message.replace(/<[^>]*>/g, '')
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.chat-message {
  display: flex;
  width: fit-content;
  max-width: 90%;
  margin-top: 1rem;

  .chat-message-content-body {
    padding: 0.4375rem 0.875rem;

    .system-message-text {
      a {
        color: #eee !important;
      }
      color: #ccc;
    }
  }

  .chat-message-side {
    width: 46px;
    min-width: 46px;
  }

  .chat-message-content-head {
    display: flex;
    margin-bottom: 0.3rem;

    .chat-datetime {
      font-size: 0.75rem;
    }
  }

  .chat-system-label {
    font-size: 0.625rem;
    line-height: 2;
  }
}
</style>
